import HubspotForm from "react-hubspot-form"
import React from "react"
import { format, parseISO } from 'date-fns'

import Banner from "../components/banner"

import Layout from "../components/layout"

const GatedContentForm = ({ pageContext }) => {
  const formattedPublishedDate = pageContext.date ? format(parseISO(pageContext.date), 'MM-dd-yyyy') : '';
  return (
    <Layout>
      <Banner
        mediaType={pageContext.format}
        mediaDuration={pageContext.duration}
        publishedDate={formattedPublishedDate}
        mediaText={pageContext.title}
        isFormPage={true}
      />
      <section className="container insight-form">


        <div className="hubspot-form">
          <HubspotForm
            className="request-form"
            portalId={pageContext.portal}
            formId={pageContext.formId}
            redirectUrl={pageContext.destinationUrl}
          />
        </div>
        <div className="request-text">
          <p className="paragraph-text">
            { pageContext.content ? pageContext.content.replace(/(<p>|<\/p>)/g, '') : `Connect with us to see why CareAdvisors is the preferred platform
              for hospitals and health plans for Social Care Automation for Community Health.` }
          </p>

          {
            pageContext.typeOfMedia === "pdf"
            && pageContext.featuredImage
            && <div className="pdf-thumbnail">
                <img
                  srcSet={pageContext.featuredImage.node.srcSet}
                  style={{ width: '100%' }}
                  alt={pageContext.featuredImage.title}
                />
               </div>
          }

          {pageContext.presenters && <div className="presenters">
            <p className="paragraph-text">Presenters</p>
            <hr />
            <div className="presenters-cards">

              {pageContext.presenters.map((presenter, index) => {
                return (
                  <div key={index}>
                    <div className="presenter-card">
                      <img srcSet={presenter.photo.srcSet} sizes="(min-width: 2048px) 2048px, (min-width: 1536px) 1536px, (min-width: 1024px) 1024px, (min-width: 768px) 768px, 100vw" alt={presenter.title} className="presenter-photo" />
                      <div className="presenter-caption">
                        <h4 className="presenter-name">{presenter.subtitle}</h4>
                        <p className="body-text presenter-title">{presenter.title}</p>
                      </div>
                    </div>
                    <hr />
                  </div>
                )
              })}

            </div>
          </div>}

        </div>
      </section>
    </Layout>
  )
}

const getLongDate = (date) => {

  const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
  ];

  const fullDate = new Date(Date.parse(date));
  let eventMonth = monthNames[fullDate.getMonth()];
  let eventDate = fullDate.getDate();
  let eventYear = fullDate.getFullYear();

  return eventMonth + ' ' + eventDate + getOrdinal(eventDate) + ', ' + eventYear;
}

const getOrdinal = (date) => {

  if (date > 3 && date < 21) return 'th';
  switch (date % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

export default GatedContentForm
