import React from 'react'

const Banner = ({mediaType, mediaDuration, mediaText, isFormPage, publishedDate}) => (
    <aside className={`${isFormPage ? " banner-form" : "banner"}`}>
        <div className="media-attributes">
            <div className={`media-type media-type-${mediaType}`}><h4>{mediaType}</h4></div>
            <div className="media-duration"><h4>{publishedDate} | {mediaDuration}</h4></div>
        </div>
        <h1>{mediaText}</h1>
    </aside>
)

export default Banner